import React from 'react';
import { Container } from 'emotion-flex';
import { FormattedMessage } from 'react-intl';
import SEO from '../components/SEO';
import EmptyState from '../components/EmptyState';
import Link from '../components/Link';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const EmailWrongLink = () => {
  useBrowserLocale();
  return (
    <>
      <SEO title="Error verifying email address" />
      <Container>
        <EmptyState
          title="Error verifying email address"
          text={
            <div>
              <p>
                Error, account restoration was not successful (wrong link).
                Please try pasting the link from the email manually into your
                browser’s address bar.
              </p>
            </div>
          }
          action={
            <ButtonLink to="https://cara.app.link/Dk8UOqildy">
              <FormattedMessage
                id="pages.email.continueToApp"
                defaultMessage="Continue to App"
              />
            </ButtonLink>
          }
          withImage
        />
      </Container>
    </>
  );
};

export default withLayout(EmailWrongLink, { isNewLayout: true });
